import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from '../cookies/cookie.service';
import { TimeService } from '../time/time.service';
import { Store } from '@ngrx/store';
import { LoyaltyActions } from '../../store/loyalty/actions/loyalty.actions';
import { Socket, io } from 'socket.io-client';
import {
  CustomCashback,
  SuccessItemPurchase,
  tradableBalance,
  userRewardedStatistics,
  userTurnover,
} from '../../interfaces/interfaces';
import { SetUserBalances } from '../../store/user/actions/user.actions';
@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  // chnage URL to your API in NGINX and in angular proxy.conf.json
  // private readonly apiUrl = 'https://staging.coduluz.com/api/v2/loyalty/';

  private socket!: Socket;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private timeService: TimeService,
    private store: Store
  ) {}
  //Rakeboost
  // getRakeBoost() {
  //   return this.http.post(`/api/v2/loyalty/boosts/get`, {});
  // }
  getCashackCustom() {
    return this.http.post<CustomCashback>(`/api/v2/loyalty/cashback-custom/get-user-cashback`, {});
  }
  collectCashackCustom() {
    return this.http.post(`/api/v2/loyalty/cashback-custom/collect-user-cashback`, {});
  }
  //Balance
  getTradableBalance(): Observable<tradableBalance> {
    return this.http.post<tradableBalance>(`/api/v2/loyalty/tradable-balance/get-tradable-balance`, null);
  }
  getUserRewardedStatistics(): Observable<userRewardedStatistics> {
    return this.http.post<userRewardedStatistics>(
      `/api/v2/loyalty/tradable-balance/get-user-rewarded-statistics`,
      null
    );
  }
  //turnover
  getUserTurnover(): Observable<userTurnover> {
    return this.http.post<userTurnover>(`/api/v2/loyalty/turnover/get-user-turnover`, null);
  }
  //cashback
  getUserCashback() {
    return this.http.post(`/api/v2/loyalty/cashback/get-user-cashback`, null);
  }
  takeUserCashback(type: number) {
    return this.http.post(`/api/v2/loyalty/cashback/collect-user-cashback`, { type });
  }
  //Rakeback
  collectRakeback() {
    return this.http.post(`/api/v2/loyalty/rake-balance/collect-rakeback`, null);
  }
  getRakebackBalance() {
    return this.http.post(`/api/v2/loyalty/rake-balance/get-rake-balance`, null);
  }
  //wellbet
  collectWellbet() {
    return this.http.post(`/api/v2/loyalty/welle-bet-balance/collect-welle-bet`, null);
  }
  getWellbetBalance() {
    return this.http.post(`/api/v2/loyalty/welle-bet-balance/get-welle-bet-balance`, null);
  }

  getUserBonusRate() {
    return this.http.post(`/api/v2/loyalty/user/get-user-bonus-rates`, null);
  }
  //Ranks
  getUserRank() {
    return this.http.get(`/api/v2/loyalty/rank/get-user-rank`);
  }
  getCollectedUserRanks() {
    return this.http.post(`/api/v2/loyalty/rank/get-collected-user-ranks`, null);
  }
  getRanks() {
    return this.http.get(`/api/v2/loyalty/rank/get-ranks`);
  }
  collectRank(rankId: number) {
    return this.http.post(`/api/v2/loyalty/rank/collect-rank`, { rankId });
  }
  //calendar
  getCalendarActions(startDate: Date, endDate: Date) {
    return this.http.post(`/api/v2/loyalty/calendar/get-user-calendar-actions`, {
      startDate,
      endDate,
    });
  }
  collectCalendarAction(id: number) {
    return this.http.post(`/api/v2/loyalty/calendar/collect-action`, {
      id,
    });
  }
  //shop
  getAllShopItems() {
    return this.http.get(`/api/v2/loyalty/shop/all-items`);
  }
  buyItemFromStore(itemId: number, userId: string) {
    return this.http.post(`/api/v2/loyalty/shop/buy-item`, {
      itemId: itemId,
      login: userId,
    });
  }
  activeBonusEvent(itemId: number, userId: string) {
    return this.http.post(`/api/v2/loyalty/shop/actived-bonus-event`, {
      itemId: itemId,
      login: userId,
    });
  }
  getShopHistory() {
    return this.http.get(`/api/v2/loyalty/shop/history`);
  }

  getRankColor(rankName: string) {
    if (rankName?.includes('cabin_boy')) {
      return 'text-darkBlueBgLight';
    } else if (rankName?.includes('boatswain')) {
      return 'text-textBlueLight';
    } else if (rankName?.includes('admiral')) {
      return 'text-[#075985]';
    } else if (rankName?.includes('captain')) {
      return 'text-brownGoldLight';
    } else if (
      rankName?.includes('legend_of_the_seas') ||
      rankName?.includes('triton') ||
      rankName?.includes('poseidon')
    ) {
      return 'text-blackTextLight';
    }
    return '';
  }

  connect(url: string, userId: string, login: string): Observable<any> {
    this.socket = io(url, {
      transports: ['websocket'],
    });

    return new Observable((observer) => {
      this.socket.on('connect', () => {
        observer.next('connected');
        this.sendSocketUserId(userId, login);
      });

      this.socket.on('disconnect', () => {
        observer.next('disconnected');
      });

      this.socket.on('send-event', (message) => {
        observer.next(message);
      });

      this.socket.on('error', (err) => {
        observer.error(err);
      });

      return () => {
        this.socket.disconnect();
      };
    });
  }

  sendSocketUserId(userId: string, login: string): void {
    if (this.socket?.connected) {
      this.socket.emit('saveUserId', { userId, login });
    }
  }
  sendSocketShopBonus(item: SuccessItemPurchase): void {
    this.socket.emit('shopBonusActivated', item);
  }
  sendSocketActiveBonus(isActiveBonus: boolean, userId: string): void {
    if (!userId) return;
    this.socket.emit('is-bonus', {
      isActiveBonus,
      userId,
    });
  }
  disconnect(): void {
    this.socket.disconnect();
  }
  addDataFromSocket(res: any) {
    switch (res.eventKey) {
      //   case 'UPDATE_WELLE_BET_BALANCE':
      //     res.data.nextAvailableAt += 'Z';
      //     this.store.dispatch(LoyaltyActions['[Loyalty]SetWellBetSuccess']({ data: res.data }));
      //     break;
      //   case 'UPDATE_RAKE_BALANCE':
      //     res.data.nextAvailableAt += 'Z';
      //     this.store.dispatch(LoyaltyActions['[Loyalty]SetReefbackSuccess']({ data: res.data }));
      //     break;
      //   case 'UPDATE_TRADABLE_BALANCE':
      //     this.store.dispatch(LoyaltyActions['[Loyalty]SetTradableBalanceSuccess']({ data: res.data }));
      //     break;
      //   case 'UPDATE_REWARDED_STATISTICS':
      //     this.store.dispatch(LoyaltyActions['[Loyalty]SetRewardedStatisticsSuccess']({ data: res.data }));
      //     break;
      case 'UPDATE_TURNOVER_BALANCE':
        this.store.dispatch(LoyaltyActions['[Loyalty]SetUserTurnoverSuccess']({ data: res.data }));
        break;
      case 'CHANGE_BALANCE':
        this.store.dispatch(SetUserBalances['[SetUserBalances]SetUserBalanceSuccess']({ data: res.data }));
        break;
    }
  }

  filtreBoost(boost: any) {
    const now = new Date();
    const activeBoosts = boost.filter((b: any) => new Date(b.activeTo) >= now);
    if (activeBoosts.length === 0) {
      return null;
    }
    const closestBoost = activeBoosts.reduce((closest: any, current: any) => {
      const currentDiff = Math.min(
        Math.abs(new Date(current.activeFrom).getTime() - now.getTime()),
        Math.abs(new Date(current.activeTo).getTime() - now.getTime())
      );

      const closestDiff = Math.min(
        Math.abs(new Date(closest.activeFrom).getTime() - now.getTime()),
        Math.abs(new Date(closest.activeTo).getTime() - now.getTime())
      );

      return currentDiff < closestDiff ? current : closest;
    });

    return closestBoost;
  }
  filtreBoostActive(boost: any) {
    const now = new Date();

    const activeBoosts = boost.filter(
      (b: any) => new Date(b.activeFrom) <= now && new Date(b.activeTo) >= now && b.isActive
    );

    if (activeBoosts.length === 0) {
      return null;
    }

    return activeBoosts;
  }
}
